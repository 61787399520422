import React from 'react'
import styled from 'styled-components'

interface Props {
	value: string;
}

const Label = (props: Props) => {
	return <Wrapper>{props.value}</Wrapper>;
}

const Wrapper = styled.label`
	font-size: 2rem;
	text-align: center;
	display: block;
`;

export default Label