import React from 'react'
import styled from 'styled-components'

interface Props {
	value: number;
	label: string;
	footLabel? : string;
	name: string;
	onChange: (e:any) => void;
	inputRef?: React.RefObject<HTMLInputElement>;
}

const NumForm = (props: Props) => {
	return ( 
		<Label>
			{props.label}
			<Input 
				type="text" 
				name={props.name} 
				value={props.value}  
				onChange={(e:any) => props.onChange(e)} 
				ref = {props.inputRef}
				onKeyPress={(e:any) => {if(e.key==='Enter'){ e.target.blur()  }}}
			/>
			{props.footLabel}
		</Label>
	);
}

const Label = styled.label`
	font-size: 2rem;
	padding-right: 1rem;
`;

const Input = styled.input`
	width: 4rem;
	font-size: 1.7rem;
	text-align: center;
	
`;

export default NumForm