import React from 'react'
import ReactDOM from 'react-dom'
import Hotkeys from 'react-hot-keys'
import Body from './Main/Body'
import Head from './Main/Head'
import Foot from './Main/Foot'
import Wave from './Main/Wave'
import ListView from './Cover/ListView'
import styled from 'styled-components'
import _ from 'lodash';
import {Customer} from './Models/CustomerInterface';

let beforeChangeCustomers: Customer[] = [];

// const DOMAIN = "../TestData";//テスト用。ローカルのダミーファイル
const GET_METHOD = "/api/transactions.json";
const POST_METHOD = "/api/transactions.json";

function difference(affters: Customer[], bases: Customer[]) {
	return _.differenceWith(affters, bases, _.isEqual)
}
interface Props {

}

interface State {
	customers:Customer[];
	modifyCustomers:Customer[];
	removeCustomers: Customer[];
	selectedIndex: number;
	witchListViewAppear: string;
	httpGetStatus: string;
	httpGetMessage: string;
	domain: string;
}

class App extends React.Component<Props, State> {
	private first: React.RefObject<HTMLInputElement>;
	constructor(props: Props) {
		super(props);
		this.first = React.createRef();
		this.state = {
			customers: Array(0),
			modifyCustomers: Array(0),
			removeCustomers: Array(0),
			selectedIndex: 0,
			witchListViewAppear: 'none',
			httpGetStatus: 'yet',
			httpGetMessage: '読み込み中',
			domain: ''
		};
	}

	/*
		Life Cycles
	*/
	componentDidMount() {
		let query = (window.location.search);
		this.getDataManager(query);
	}
	
	/*
		Handles
	*/
	handleArrowButtonClick(isLeft:boolean){
		const selectedIndex = this.state.selectedIndex;
		const count = this.state.customers.length;
		let nextIndex = selectedIndex;
		if (isLeft){
			if (selectedIndex === 0){return;}
			nextIndex -= 1;
		}else{
			if (selectedIndex === count - 1){return;}
			nextIndex += 1;
		}
		this.setState({
			selectedIndex:nextIndex
		});
	}

	handleValueChange(event: any) {
		let customers:Customer[] = this.state.customers.slice();
		let customer:Customer = customers[this.state.selectedIndex];
		const name:string = event.target.name;
		const value:string = event.target.value;
		console.log(name+':'+value);
		if (name === 'f_weight'||name === 'i_weight'){
			customer[name] = this.numOnly(value,false);
		}else if (name === 'amount'){
			customer.others[event.otherKey][name] = this.numOnly(value, true);
		}else if (name === 'others_name'){
			customer.others[event.otherKey][name] = value;
		}
		this.setState({
			customers: customers
		});
	}

	handleClickOtherPlusButton(){	
		let customers = this.state.customers.slice();
		let customer = customers[this.state.selectedIndex];
		customer.others.push({
			id: 0,
			others_name:'',
			amount:0,
			deleted_flag: false,
		});
		this.setState({
			customers: customers
		});
	}

	handleClickOtherDeleteButton(index: number){	
		let customers = this.state.customers.slice();
		let customer = customers[this.state.selectedIndex];
		customer.others[index].deleted_flag = true;
		this.setState({
			customers: customers
		});
	}

	handleClickFootButton(buttonType:string){
		if (buttonType === 'customerList'){
			this.setState({witchListViewAppear:'customerList'});
		}
		if (buttonType === 'modifyList'){
			this.emptyOthersDel();
			this.modifyExtract();
			this.setState({witchListViewAppear:'modifyList'});
		}
		if (buttonType === 'close'){
			this.setState({witchListViewAppear:'none'});
		}
		if (buttonType === 'send'){
			this.postData();
		}
	}

	handleClickJump(code:string){
		if (code){
			const index = this.state.customers.findIndex(customer => customer.code === code);
			this.setState({
				selectedIndex: index,
				witchListViewAppear:'none'
			});
		}
	}

	onKeyUp(keyName:string, e: any, handle: any) {
		console.log(keyName);
		if(keyName === 'enter' && this.first.current != null){
			this.first.current.focus();
		}
		if(keyName === 'left'){
			this.handleArrowButtonClick(true);
		}
		if(keyName === 'right'){
			this.handleArrowButtonClick(false);
		}
	}

	/*
		JSON Data get and post
	*/
	getDataManager(query: string){
		beforeChangeCustomers = [];
		this.setState({
			httpGetStatus:'yet',
			httpGetMessage: '読み込み中',
		});
		//urlパラメータ->オブジェクト変換
		let params:any = {}
		query.substring(1).split('&').forEach( param => {
			const temp = param.split('=')
			params = {
				...params,
				[temp[0]]: ((temp[0] === 'o')||(temp[0] === 'l')) ? Number(temp[1]) : temp[1]
			}
		})

		//アクセス元
		let _domain:string = params['url']
		console.log(_domain);
		this.setState({
			domain: _domain
		});
		this.getData(params);
	}

	getData(params: any){
		let query: string = '?q='+params['q']+'&d='+params['d']+'&o='+params['o']+'&l='+params['l']+'&cid='+params['cid'];

		console.log(params['url']+GET_METHOD+query);


		
		fetch(params['url']+GET_METHOD+query)
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.result !== 'success'){
				this.setState({
					httpGetStatus: responseJson.result,
					httpGetMessage: responseJson.message,
				});
				return;
			}

			if (responseJson.customers.length === 0){
				this.setState({
					httpGetStatus: "fail",
					httpGetMessage: "0件でした",
				});
				return;
			}
			
			//変更前の顧客オブジェクト配列
			beforeChangeCustomers = beforeChangeCustomers.concat(JSON.parse(JSON.stringify(responseJson.customers)));
			//変更予定の顧客オブジェクト配列
			let thisCustomers = this.state.customers.concat(responseJson.customers);
			this.setState({
				customers: thisCustomers,
			});
			console.log(responseJson.customers);
			console.log(responseJson.customers.length);
			if (responseJson.customers.length !== 0){
				let newParams = params;
				newParams['o'] = params['o']+params['l'];
				this.getData(newParams);
			}
		})
		.catch((error) => {
			console.error(error);
			this.setState({
				httpGetStatus: "fail",
				httpGetMessage: "内部エラーです",
			});
			return;
		});
	}

	postData(){
		this.emptyOthersDel();
		this.modifyExtract();
		// this.removeExtract();
		let query = (window.location.search);
		let params:any = {}
		query.substring(1).split('&').forEach( param => {
			const temp = param.split('=')
			params = {
				...params,
				[temp[0]]: temp[1]
			}
		})

		console.log(this.state.modifyCustomers);
		const customers = this.state.modifyCustomers;
		let obj = {
			'customers' : customers,
			'q' : params['q'],
			'd' : params['d']
		}
		const method = "POST";
		const body = JSON.stringify(obj);
		const headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};
		console.log(this.state.domain+POST_METHOD);
		fetch(this.state.domain+POST_METHOD, {method, headers, body})
		.then((res) => res.json())
		.then(console.log)
		.catch(console.error);

		this.postedDel();
	}

	/*
		Others
	*/

	//値が入っていないその他オブジェクトを削除する
	emptyOthersDel(){
		let customers = this.state.customers.concat();
		customers = customers.map(function (customer){
			customer.others = customer.others.filter(other =>((other.id !== 0) || (other.others_name !== '')))
			return customer
		});
		this.setState({
			customers: customers
		})
	}

	//変更したjsonオブジェクトの配列を作成する
	modifyExtract(){
		let editedCustomers = this.state.customers.concat();
		let result = difference(editedCustomers,beforeChangeCustomers);
		this.setState({modifyCustomers: result});
	}

	//postしたオブジェクトを除外する
	postedDel(){
		//変更したオブジェクトのcustomer_id配列
		const modifyCustomers = this.state.modifyCustomers;
		const delIds = modifyCustomers.map(function(modifyCustomer){
			return modifyCustomer.id
		});

		//操作する配列から、post済のオブジェクトを削除
		let customers = this.state.customers.concat();
		customers = customers.filter(function(customer){
			return !delIds.includes(customer.id)
		});
		this.setState({
			customers: customers
		})

		//変更検知用の複製配列から、post済のオブジェクトを削除
		beforeChangeCustomers = beforeChangeCustomers.filter(function(customer){
			return !delIds.includes(customer.id)
		});
	}
	
	numOnly = (value: string, allowMinus: boolean) => {
		console.log(value);
		var n = Number(value.replace(/[^0-9]/g, ''));
		if(value.startsWith('-') && allowMinus){
			n *= -1;
		}
		return n
	}
	
	/*
		Render
	*/
	render() {
		if (this.state.customers.length === 0){
			return (
				<>
					<Message>{this.state.httpGetMessage}</Message>
					<Wave/>
				</>
			);
		}

		const selectedIndex = this.state.selectedIndex;
		let prevCustomer = null;
		let nowCustomer = null;
		let nextCustomer = null;
		
		if (this.state.customers.length > 0){
			if (selectedIndex !== 0){
				prevCustomer = this.state.customers[selectedIndex-1];
			}
			nowCustomer = this.state.customers[selectedIndex];
			if (selectedIndex !== this.state.customers.length-1){
				nextCustomer = this.state.customers[selectedIndex+1];
			}
		}
		if (nowCustomer == null){
			return (<p></p>);
		}
		return (
			<W>
				<Hotkeys 
					keyName="enter,left,right" 
					onKeyUp={this.onKeyUp.bind(this)}
				/>
				<Head prev={prevCustomer}  next={nextCustomer} />
				<Body 
					customer={nowCustomer} 
					selectedIndex={selectedIndex} 
					onClick={(isLeft: boolean) => this.handleArrowButtonClick(isLeft)} 
					onChange={(e) => this.handleValueChange(e)}
					onClickOtherAdd={() => this.handleClickOtherPlusButton()}
					onClickOtherDelete={(otherKey: number) => this.handleClickOtherDeleteButton(otherKey)}
					inputRef = {this.first}
				/>
				<Foot onClick={(e) => this.handleClickFootButton(e)}/>
				<Wave/>
				<ListView
					witchListViewAppear={this.state.witchListViewAppear}
					customers={this.state.customers}
					modifyCustomers={this.state.modifyCustomers} 
					onClick={(e) => this.handleClickFootButton(e)}
					onClickJump={(e) => this.handleClickJump(e)}
				/>
			</W>
		);
	}
}

const Message = styled.p`
	margin: 50px;
	font-size: 2rem;
	color: #ffae00;
`;

const W = styled.div`
	height: 98vh;
	overflow: hidden;
	position: relative;
	overflow-y: scroll;
`;

ReactDOM.render(<App />, document.getElementById("root"));
