import React from 'react'
import styled from 'styled-components'

const Wave = () => {
	return ( 
		<Ocean>
			<WaveDiv/>
			<WaveDiv/>
			<WaveDiv/>
		</Ocean>
	);
}

const Ocean = styled.div`
	width:100%;
	position:fixed;
	bottom:0;
	left:0;
	z-index: -5;
`;

const WaveDiv = styled.div`
	background: url(./SVG/wave3.svg) repeat-x; 
	position: absolute;
	top: -114px;
	width: 6400px;
	height: 114px;
	animation: wave 27s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite, swell 15s ease -1.25s infinite;
	transform: translate3d(0, 0, 0);
	opacity: 0.7;
	&:nth-of-type(2) {
		background: url(./SVG/wave2.svg) repeat-x; 
		top: -50px;
		height: 112px;
		animation: wave 30s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite;
	}

	&:nth-of-type(3) {
		background: url(./SVG/wave1.svg) repeat-x; 
		top: -130px;
		height: 200px;
		animation: wave 40s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 20s ease -1.25s infinite;
	}

	@keyframes wave {
		0% {
			margin-left: 0;
		}
		100% {
			margin-left: -1600px;
		}
	}

	@keyframes swell {
		0%, 100% {
			transform: translate3d(0,25px,0);
		}
		50% {
			transform: translate3d(0,0px,0);
		}
	}
`;

export default Wave