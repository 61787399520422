import React from 'react'
import CustomerList from './CustomerList'
import {Customer} from '../Models/CustomerInterface';

interface Props {
	witchListViewAppear: string;
	customers: Customer[];
	modifyCustomers: Customer[];
	onClick: (e:string) => void;
	onClickJump: (e:string) => void;
}

const ListView = (props: Props) => {
	if (props.witchListViewAppear==='none'){
		return null;
	}else if  (props.witchListViewAppear==='customerList' || props.witchListViewAppear==='modifyList'){
		const customers = props.witchListViewAppear==='customerList' ? props.customers : props.modifyCustomers;
		return ( 
			<CustomerList 
				witchListViewAppear={props.witchListViewAppear}
				customers={customers}
				onClick={(e:string) => props.onClick(e)}
				onClickJump={(e:string) => props.onClickJump(e)}
			/>
		);
	}else{
		return null;
	}
}
export default ListView