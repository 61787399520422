import React from 'react'
import styled from 'styled-components'
import CustomerInfo from './CustomerInfo'
import { Customer } from '../Models/CustomerInterface';

interface Props {
	prev:Customer | null | undefined;
	next:Customer | null | undefined;
}

const Head = (props: Props) => {
	return (
		<Wrapper>
			<CustomerInfo customer={props.prev} />
			<CustomerInfo customer={props.next} />
		</Wrapper>);
}

const Wrapper = styled.div`
	display: flex;
`;

export default Head