import React from 'react'
import styled from 'styled-components'

interface Props {
	value: string;
	onClick: () => void;
}

const BasicButton  = (props: Props) => {
	return <Button onClick={props.onClick}>{props.value}</Button>;
}

const Button = styled.button`
	display: inline-block;
	font-size: 1.5rem;
	padding: 0.2em 1em;
	text-decoration: none;
	background: #ffdbae;
	color: #FFF;
	border: none;
	border-radius: 2px;
	&:hover {	
		transform: translateY(2px);
		transition: 0.5s;
		background: #FFA07A;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
	}
`;


export default BasicButton