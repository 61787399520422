import React from 'react'
import styled from 'styled-components'
import RoundButton from './RoundButton';

interface Props {
	name: string;
	amount: number;
	onChange: (e:any) => void;
	onClickDelete: (oKey: number ) => void;
	otherKey: number;
}

const OtherForm = (props: Props) => {
	return ( 
		<Wrpper>
			<Input 
				type="text" 
				name="others_name" 
				placeholder="その他" 
				value={props.name} 
				onChange={(e: any) => {e.otherKey = props.otherKey; props.onChange(e)}}
				onKeyPress={(e: any) => {if(e.key==='Enter'){ e.target.blur() }}}
			/>：
			<Input 
				type="text" 
				name="amount" 
				placeholder="料金(円)" 
				value={props.amount} 
				onChange={(e: any) => {e.otherKey = props.otherKey; props.onChange(e)}}
				onKeyPress={(e: any) => {if(e.key==='Enter'){ e.target.blur() }}}
			/>
			<RoundButton value="×" onClick={() => {props.onClickDelete(props.otherKey) }}/>
		</Wrpper>
	);
}

const Wrpper = styled.div`
	font-size: 2rem;
`;

const Input = styled.input`
	width: 12rem;
	font-size: 2rem;
	text-align: center;
	margin: 0.5rem;
`;

export default OtherForm